
.App {
  text-align: center;
}
.App-logo {
  width: 4em;
  margin: 0;
  padding: 0;
}

.hover:hover {
  cursor: pointer;
}

.App-header {
  background-color: #fff8f2;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  margin: 0;
  padding: 0;
}

.App-logo:hover, .app-p {
  cursor: pointer;
}

.fox {
  height: 5000px;
}

.app-p {
  margin: 0;
  font-size: calc(10px + 1vmin);
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.App-link {
  color: #61dafb;
}

