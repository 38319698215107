@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;600;700&display=swap');

.ministry {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    text-align: center;
    background: #B7D365;
    padding: 20px;
}

.Kainene {
    background: url('grids.png') no-repeat;
    background-size: cover;
    width: 100vw;
    min-height: 100vh;
    overflow: hidden;
    font-family: 'Open Sans', sans-serif;
    position: relative;
}

nav {
    position: fixed;
    width: 100vw;
    z-index: 1000;
}

.hold {
    height: 100px;
}


ul > .left {
    width: 100px;
    height: 100px;
}

ul > .left > img {
    width: 100%;
    height: 100%;
}

.About {
    max-width: 700px;
    height: max-content;
    background: white;
    margin: 0 auto;
    padding: 60px;
    box-shadow: rgba(24, 6, 55, 0.2) 0px 8px 24px;
    border-radius: 20px;
    margin-top: 80px;
}

.Pricing {
    max-width: 700px;
    height: max-content;
    background: white;
    margin: 0 auto;
    padding: 60px;
    margin-top: 80px;
}
.aboutheader > h1 {
    font-size: 35px;
    line-height: normal;
}

.aboutheader > h2 {
    font-size: 18px;
    font-weight: 250;
}

.aboutheader > p {
    font-size: 20px;
}
.loading {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

ul {
    list-style-type: none;
    padding-top: 40px;
    padding-left: 10px;
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

li:hover {
    cursor: pointer;
}

ul > .right {
    text-align: right;
}

ul > .right > * {
    display: inline;
    padding: 0px 50px;
    cursor: pointer;
}

a {
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: black;
}

a:hover {
    color: red;
}

.jumbo {
    display: flex;
    margin-top: -40px;
    position: relative;
    z-index: 3;
    width: 100vw;
    justify-content: space-between;
    align-items: center;
}

.left {
    width: 50%;
    padding-left: 60px;
}

.right {
    width: 60%;
}

h1 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 70px;
    line-height: 1;
    margin-bottom: -20px;
    color: #4B4B4B;
}

p {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 25px;
    color: #4B4B4B;
}

img {
    width: 100%;
}

button {
    background: #2e2e2e;
    border: none;
    box-shadow: none;
    color: white;
    padding: 13px 20px;
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    border-radius: 15px;
    overflow: hidden;
    cursor: pointer;
}

button:hover {
    background: #797979;
}

@media screen and (max-width: 1200px) {
    .jumbo {
        flex-direction: column;
        position: relative;
        height: 100vh;
        justify-content: center;
    }

    ul > * {
        padding: 10px;
        /* display: block; */
    }

    ul > .left {
        width: 80px;
    }

    .About {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        box-shadow: none;
        background: none;
        padding: 20px;
    }

    .Pricing {
        padding: 20px;
    }


    h1 {
        font-size: 50px;
    }

    .aboutheader > h1 {
        font-size: 25px;
        line-height: normal;
    }
    
    .aboutheader > h2 {
        font-size: 15px;
        font-weight: 250;
    }

    .aboutheader > p {
        font-size: 17px;
    }

    ul .right > * {
        padding: 0px 20px;
    }

    .left {
        width: 100%;
        padding: 0px 20px;
    }

    .right {
        position: absolute;
        z-index: -1;
        width: 100vw;
    }

    img {
        height: 200vh;
        width: 200vh;
    }
}

@media screen and (max-width: 800px) {
    .right {
        position: absolute;
        z-index: -1;
        width: 100vw;
    }

    h1 {
        font-size: 35px;
    }

    .About {
        max-width: 100vw -80px;
    }

    p {
        font-size: 20px;
    }

    img {
        height: 65vh;
        width: 65vh;
    }
}